import React from "react"
import { graphql } from "gatsby"
import Navbar from "../../components/NavBar"
import Carousel from "../../components/Carousel"
import Footer from "../../components/Footer"
import Metatags from "../../components/metatags"
import UseSiteMetadata from "../../components/UseSiteMetadata"

const ClickDoBemEN = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "pt-BR",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | Click do Bem"
        description="website para Click do Bem"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <Navbar />
      <div className="h-10" />
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <h1>Click do Bem</h1>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-6">
            <p>
              Click do Bem é um portal de captação de recursos para entidades do
              terceiro setor no Brasil.
            </p>
            <p>
              As entidades podem registrar-se, explicar sobre seus projetos e
              metas e solicitar doações.
            </p>
            <p>
              Os doadores podem fazer doações com um mínimo de dados pessoais
              para as formas mais simples de pagamento (boleto e pix do Brasil),
              ou criar uma conta em que podem regitrar cartões de crédito e
              acessar seu histórico de doações.
            </p>
          </div>
        </div>
        <div className="h-8"/>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">desenvolvimento</div>
          <div className="col-span-4 tec-feature">
            gatsby js
            <br />
            nest js
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">integrações</div>
          <div className="col-span-4 tec-feature">
            Empresa Brasileira de Correios e payment gateway.
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">implantação</div>
          <div className="col-span-4 tec-feature">
            aws S3, cloudfront, dynamoDB, lambda e API gateway
          </div>
        </div>
        <div className="h-10" />
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel images={data.portfolioImages.edges} alt="click do bem" />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="pt" />
    </>
  )
}

export default ClickDoBemEN

export const query = graphql`
  {
    portfolioImages: allFile(
      filter: { relativePath: { regex: "/webs/clickdobem/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1280)
          }
        }
      }
    }
  }
`
